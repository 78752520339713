<template>
  <b-card>
    <b-overlay
      :show="!leads"
      rounded="sm"
    >
      <div class="pending-leads-table">
        <!-- search input -->
        <div style="display:flex;gap:10px;">
          <b-button
            v-b-modal.templates-modal
            class="btn btn-primary"
            variant="primary"
          >
            Add a template
          </b-button>
          <b-button
            v-b-modal.templates-select
            class="btn btn-primary"
            variant="primary"
          >
            Template select
          </b-button>
        </div>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="leads"
          ref="table-pending"
          :columns="columns"
          :rows="leads"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <div slot="selected-row-actions">
            <button
              class="btn btn-sm btn-primary mr-1"
              @click="approveSelected"
            >
              Approve selected
            </button>
            <button
              class="btn btn-sm btn-danger"
              @click="rejectSelected"
            >
              Reject
            </button>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Demo Version -->
            <span v-if="props.column.field === 'demo_name'">
              <b-badge :variant="demoVersion(props.row.demo_name)">
                {{ props.row.demo_name }}
              </b-badge>
            </span>

            <!-- Column: Website -->
            <span v-else-if="props.column.field === 'website'">
              <p class="truncate"><a
                v-b-tooltip.hover.focus.v-primary
                :href="props.row.website"
                target="_blank"
                :title="props.row.website"
              >{{ props.row.website }}</a></p>
            </span>

            <!-- Column: Instant Messenger -->
            <a
              v-else-if="props.column.field === 'messaging_tool'"
              class="m-0"
              target="_blank"
              :href="instantMessage(props.row.messaging_tool, props.row.contact)"
            >
              <i
                v-if="props.row.messaging_tool === 'Skype'"
                v-b-tooltip.hover.focus.v-primary
                title="Skype"
                class="bi bi-skype"
              />
              <i
                v-else-if="props.row.messaging_tool === 'Whatsapp'"
                v-b-tooltip.hover.focus.v-primary
                title="Whatsapp"
                class="bi bi-whatsapp"
              />
              <i
                v-else-if="props.row.messaging_tool === 'Telegram'"
                v-b-tooltip.hover.focus.v-primary
                title="Telegram"
                class="bi bi-telegram"
              />
            </a>

            <!-- Column: Presentation -->
            <span v-else-if="props.column.field === 'with_platform_presentation'">
              <feather-icon
                :icon="props.row.has_platform_presentation ? 'CheckIcon' : 'XIcon'"
                class="inline-flex"
                :class="props.row.has_platform_presentation ? 'text-success' : 'text-danger'"
                size="21"
              />
            </span>

            <!-- Column: Notes -->
            <span v-else-if="props.column.field === 'notes'">
              <router-link
                v-b-tooltip.hover.focus.v-primary
                :to="'/leads/' + props.row.id + '/add-note'"
                :class="props.row.notes ? 'bi bi-sticky-fill' : 'bi bi-sticky'"
                :title="props.row.notes"
              />
            </span>

            <!-- Column: IP -->
            <p
              v-else-if="props.column.field === 'ip'"
              class="m-0"
            >
              <a
                :id="`ip-info-${props.index}`"
                href="javascript:void(0)"
              >
                <b-tooltip
                  :target="`ip-info-${props.index}`"
                >
                  IP: {{ props.row.ip }}<br>
                  CC: {{ props.row.cc }}<br>
                  CREATED AT: {{ props.row.created_format }}<br>
                  UPDATED AT: {{ props.row.updated_format }}
                </b-tooltip>
                <feather-icon
                  icon="SearchIcon"
                  class="inline-flex"
                  size="20"
                />
              </a>
            </p>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="approveUser(props.row.id)">
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
                    />
                    <span>Approve</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="sendInvite(props.row.id, props.row.email)">
                    <feather-icon
                      icon="MailIcon"
                      class="mr-50"
                      style="margin-bottom: 4px"
                    />
                    <span>Send Invite</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="rejectUser(props.row.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                      style="margin-bottom: 4px"
                    />
                    <span>Reject</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteLeadUser(props.row.id)">
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                      style="margin-bottom: 4px"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','150']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
    <b-modal
      id="templates-modal"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Add new template"
      centered
      title="Add a template"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <b-form ref="form">
        <b-form-group
          lable="Title"
          label-for="title"
          invalid-feedback="Title and Body are required"
        >
          <b-form-input
            id="title"
            v-model="template.title"
            placeholder="Enter template title"
            :state="nameState"
            required
          />
        </b-form-group>
        <b-form-group
          invalid-feedback="Body is required"
          label-for="body"
        >
          <b-form-textarea
            id="body"
            v-model="template.body"
            style="height: 100px"
            placeholder="Enter template body"
            :state="nameState2"
            required
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Edit,Update and Copy -->
    <b-modal
      id="templates-select"
      ref="modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Copy to clipboard"
      centered
      title="Select a template"
      no-close-on-backdrop
      @ok="handleCopy"
    >
      <b-form ref="form">
        <b-form-group>
          <b-form-select
            v-model="selectedTemplate"
            @change="onChangeTemplate()"
          >
            <option
              v-for="templateOption in templates"
              :key="templateOption.id"
              :value="templateOption.body"
              editable
            >
              {{ templateOption.title }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group>
          <b-form-textarea
            id="body"
            v-model="template.body"
            style="height: 100px"
            :state="nameState2"
            required
          />
        </b-form-group>
        <div style="display:flex;justify-content: flex-start; gap:3%;">
          <b-button
            variant="success"
            size="sm"
            @click="updateTemplate()"
          >
            Update
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="deleteTemplate()"
          >
            Delete
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BForm,
  BButton,
  BBadge,
  BTooltip,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import copy from 'copy-to-clipboard'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BBadge,
    BTooltip,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      nameState: null,
      templates: [],
      selectedTemplate: '',
      selectedTemplateId: '',
      nameState2: null,
      template: {
        title: '',
        body: '',
        id: '',
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          type: 'number',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Demo Version',
          field: 'demo_name',
          sortable: false,
        },
        {
          label: 'Company',
          field: 'company',
          sortable: false,
        },
        {
          label: 'Company Description',
          field: 'companyDescription',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'email',
          sortable: false,
        },
        {
          label: 'Website',
          field: 'website',
          sortable: false,
        },
        {
          label: 'Instant Messenger',
          field: 'messaging_tool',
          sortable: false,
        },
        {
          label: 'IM Address',
          field: 'contact',
          sortable: false,
        },
        {
          label: 'Presentation',
          field: 'with_platform_presentation',
          sortable: false,
        },
        {
          label: 'Notes',
          field: 'notes',
          sortable: false,
        },
        {
          label: 'IP',
          field: 'ip',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    demoVersion() {
      const presentationColor = {
        /* eslint-disable key-spacing */
        Marketplace       : 'light-info',
        'Full Features'   : 'light-primary',
        Casino      : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return presentation => presentationColor[presentation]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    leads() {
      return this.$store.state.users.pendingLeads
    },
  },
  created() {
    this.fetchPendingLeads()
    this.fetchTemplates()
  },
  methods: {
    updateTemplate() {
      const templateToUpdate = this.templates.find(template => template.body === this.selectedTemplate)
      const payload = {
        id: templateToUpdate.id,
        title: templateToUpdate.title,
        body: this.template.body,
      }
      this.$bvModal.msgBoxConfirm('Are you sure you want to update this template?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          axios.patch('/api/admin/templates', payload).then(res => {
            if (!res) {
              this.$bvToast.toast('Something went wrong', { title: 'Error', variant: 'danger', solid: true })
              this.$refs.modal.hide()
              return
            }
            if (res.status === 200) {
              this.$refs.modal.hide()
              this.selectedTemplate = ''
              this.template.body = ''
              this.template.title = ''
              this.fetchTemplates()
              // filter the templates array to get the updated template
              const updatedTemplate = this.templates.find(template => template.id === payload.id)
              // update the selectedTemplate with the updated template
              this.selectedTemplate = updatedTemplate.body
              this.$bvToast.toast('Template updated successfully', {
                title: 'Success',
                variant: 'success',
                solid: true,
              })
            } else {
              this.$refs.modal.hide()
              this.$bvToast.toast('Something went wrong', {
                title: 'Error',
                variant: 'danger',
                solid: true,
              })
            }
          })
        }
      })
    },
    deleteTemplate() {
      // find in the templates array the template with the body that matches the selectedTemplate
      const templateToDelete = this.templates.find(template => template.body === this.selectedTemplate)
      // grab his id and delete it
      const payload = {
        id: templateToDelete.id,
        body: templateToDelete ? templateToDelete.body : '',
      }
      // ask for confirmation
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this template?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          axios.post('/api/admin/templates', payload).then(res => {
            if (!res) {
              this.$bvToast.toast('Something went wrong', { title: 'Error', variant: 'danger', solid: true })
              this.$refs.modal.hide()
              return
            }
            if (res.status === 200) {
              this.$refs.modal.hide()
              this.selectedTemplate = ''
              this.template.body = ''
              this.fetchTemplates()
              // filter the data array te remove the deleted template
              const deletedTemplate = this.templates.find(template => template.id === payload.id)
              this.templates = this.templates.filter(template => template !== deletedTemplate)
              this.$bvToast.toast('Template deleted successfully', {
                title: 'Success',
                variant: 'success',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 3000,
              })
            } else {
              this.$bvToast.toast('Something went wrong', {
                title: 'Error',
                variant: 'danger',
                solid: true,
                toaster: 'b-toaster-top-right',
                autoHideDelay: 3000,
              })
            }
          })
        }
      })
    },
    handleCopy() {
      this.$refs.modal.hide()
      copy(this.selectedTemplate)
      this.$bvToast.toast('Copied to clipboard', {
        title: 'Success',
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 3000,
      })
    },
    onChangeTemplate() {
      this.template.body = this.selectedTemplate
    },
    instantMessage(message_tool, contact) {
      const text = 'Hello and nice to e-meet you, this is Florin from TrafficManager.com , the affiliate tracking platform. I saw that you have filled out the contact form for our affiliate tracking platform online demo and I wanted to check out if have any questions regarding our product. Also, if you did not book a meeting with us yet feel free to book a call with me personally, for a live presentation of the TrafficManager platform based on your business model by accessing this link:'
      if (message_tool === 'Whatsapp') {
        return `https://api.whatsapp.com/send/?phone=${contact}&text=${text}&type=phone_number&app_absent=0`
      }
      if (message_tool === 'Telegram') {
        // remove from contact the @
        const contact_fix = contact.replace('@', '')
        return `https://telegram.me/${contact_fix}`
      }
      if (message_tool === 'Skype') {
        return `skype:${contact}?chat`
      }
      return undefined
    },
    fetchTemplates() {
      axios.get('/api/admin/templates').then(res => {
        // eslint-disable-next-line no-param-reassign
        res.data.templates = res.data.templates.filter(template => template.ext_tag !== 'yes')
        this.templates = res.data.templates
        // remove the templates with ext_tag = 'yes'
        // give the selectedTemplate the first template from the array
        this.selectedTemplate = this.templates[0].body
        this.template.body = this.selectedTemplate
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    checkFormValidity() {
      const isValid = this.$refs.form.checkValidity()
      this.nameState = isValid
      return isValid
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      const payload = {
        title: this.template.title,
        body: this.template.body,
      }
      this.$nextTick(() => {
        axios.post('/api/admin/templates_add', payload).then(res => {
          if (!res) {
            this.$bvToast.toast('Something went wrong', { title: 'Error', variant: 'danger', solid: true })
            this.$refs.modal.hide()
            return
          }
          if (res.status === 200) {
            this.$refs.modal.hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Template created successfully',
                variant: 'success',
              },
            })
            // push the data to the templates array
            this.templates.push(res.data.template)
            this.template.body = ''
            this.selectedTemplate = ''
            this.template.title = ''
          } else {
            this.$refs.modal.hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Something went wrong',
                variant: 'danger',
              },
            })
          }
        })
      })
    },
    approveSelected() {
      const rows = this.$refs['table-pending'].selectedRows
      this.confirmationAlert('Would you like to approve the selected users?')
        .then(res => {
          if (res.isConfirmed) {
            rows.forEach(row => {
              const { id } = row
              this.approveUserNoConfirmation(id)
            })
          }
        })
    },
    deleteLeadUser(id) {
      this.confirmationAlert('Would you like to delete this user?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/deleteLeadUser', id)
              .then(() => {
                this.fetchPendingLeads()
                this.$bvToast.toast('User deleted successfully', {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
                })
                this.$store.commit('users/REMOVE_PENDING_LEADS', id)
                this.$store.commit('users/REMOVE_CALLBACK_LEADS', id)
              })
              .catch(error => { console.log(error) })
          }
        })
    },
    rejectSelected() {
      const rows = this.$refs['table-pending'].selectedRows
      this.confirmationAlert('Would you like to reject the selected users?')
        .then(res => {
          if (res.isConfirmed) {
            rows.forEach(row => {
              const { id } = row
              this.rejectUserNoConfirmation(id)
            })
          }
        })
    },
    fetchPendingLeads() {
      this.$store.dispatch('users/fetchPendingLeads')
        .catch(error => { console.log(error) })
    },
    approveUser(id) {
      this.confirmationAlert('Would you like to approve this user and move it in the call back list?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/updateLeadStatus', { id, status: 2 })
              .then(response => {
                this.$store.commit('users/REMOVE_PENDING_LEADS', id)
                this.$store.commit('users/INSERT_CALLBACK_LEADS', response)
                this.notify({
                  text: 'User has been moved in the call back list!',
                  variant: 'success',
                })
                this.refreshAdminBadges()
              })
              .catch(error => { console.log(error) })
          }
        })
    },
    approveUserNoConfirmation(id) {
      this.$store.dispatch('users/updateLeadStatus', { id, status: 2 })
        .then(response => {
          this.$store.commit('users/REMOVE_PENDING_LEADS', id)
          this.$store.commit('users/INSERT_CALLBACK_LEADS', response)
          this.notify({
            text: 'User has been moved in the call back list!',
            variant: 'success',
          })
          this.refreshAdminBadges()
        })
        .catch(error => { console.log(error) })
    },
    sendInvite(id, email) {
      this.confirmationAlert(`Would you like to send an email invite to ${email}?`)
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/sendEmailInvite', id)
              .then(() => {
                this.notify({
                  text: `Email invite has been sent to ${email}`,
                  variant: 'success',
                })
              })
              .catch(error => { console.log(error) })
          }
        })
    },
    rejectUser(id) {
      this.confirmationAlert('Would you like to reject this user?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/updateLeadStatus', { id, status: 0 })
              .then(response => {
                this.$store.commit('users/REMOVE_PENDING_LEADS', id)
                this.$store.commit('users/INSERT_REJECTED_LEADS', response)
                this.notify({
                  text: 'User has been moved in the rejected list!',
                  variant: 'success',
                })
                this.refreshAdminBadges()
              })
              .catch(error => { console.log(error) })
          }
        })
    },
    rejectUserNoConfirmation(id) {
      this.$store.dispatch('users/updateLeadStatus', { id, status: 0 })
        .then(response => {
          this.$store.commit('users/REMOVE_PENDING_LEADS', id)
          this.$store.commit('users/INSERT_REJECTED_LEADS', response)
          this.notify({
            text: 'User has been moved in the rejected list!',
            variant: 'success',
          })
          this.refreshAdminBadges()
        })
        .catch(error => { console.log(error) })
    },
    refreshAdminBadges() {
      this.$store.dispatch('home/fetchAdminBadgesReset')
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.pending-leads-table {
  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  .truncate {
    width: 275px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 15px;
    margin: auto;
  }

  .bi {
    font-size: 20px;

    &.bi-skype {
      color: #00aff0;
    }

    &.bi-whatsapp {
      color: #44eb62;
    }

    &.bi-telegram {
      color: #3490ee;
    }
  }
}
.vgt-responsive{
  min-height: 170px !important;
}
</style>
